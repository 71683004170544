.home {
    .page-header {
        height: 883px; }

    .select-team {
        @include button(#fff, $color-vtheme);
        position: absolute;
        bottom: 170px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5; }

    .i-map {
        width: 400px;
        height: 600px;
        background-image: url(../img/iu-map.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        @include positionCenter;
        transform: translate(-55%, -50%);
        z-index: 4; }


    .home-logo {
        width: 213px;
        height: 273px;
        background-image: url(../img/logo-home.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        top: 107px;
        left: 126px;
        position: absolute; }

    .content-section {
        margin: 0; }

    .paralax-image-section {
        height: 630px; }

    .image-pallet-section {
        margin-top: 90px;
        margin-bottom: 90px;
        position: relative;

        .content-right {
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            left: 50%;

            .text-section {
                top: 50%;
                position: absolute;
                transform: translateY(-50%); } }

        h2 {
            text-align: left;
            padding: 0; }

        a {
            color: $color-vtheme;
            position: relative;
            display: inline-block;
            &::after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: 3px;
                left: 0;
                background-color: $color-vtheme; }

            &:hover {
                opacity: .5;
                text-decoration: none; } } } }

/* ==================================== */
/* ========== BACKGROUND VIDEO ======== */

.no-video .video-bcg video,
.touch .video-bcg video {
  display: none; }

.no-video .video-bcg .poster,
.touch .video-bcg .poster {
  display: block !important; }

.video-bcg video {
  position: absolute;
  z-index: 2;
  bottom: 0;
  opacity: .15;
  height: auto !important; }

.video-bcg video.fillWidth {
  width: 110%; }


/* ==================================== */
/* ========== ABOUT US SECTION ======== */

.home-about-us-section {
    margin-top: 120px;
    margin-bottom: 120px;

    h1 {
        font-size: 100px;
        text-transform: uppercase;
        line-height: 120px;
        color: $title-color;
        font-weight: normal;
        margin-bottom: 0;
        letter-spacing: 3px;
        margin-top: -20px; }

    h2 {
        font-size: 50px;
        line-height: 30px;
        color: $color-vtheme;
        text-transform: uppercase;
        font-weight: normal;
        margin: 30px 0 50px;
        letter-spacing: 3px; }

    h3 {
        @include contentTitle;
        color: $title-color;
        margin: 50px 0 40px; }

    p {
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 20px; }

    ul {
        @include reset-ul;
        column-count: 2;
        column-width: 50%;

        li {
            position: relative;
            padding-left: 60px;
            margin-bottom: 10px; }

        .num-list-item {
            position: absolute;
            left: 0;
            color: #fff;
            background-color: $color-vtheme;
            border-radius: 50%;
            text-align: center;
            width: 31px;
            font-size: 15px; } }

    .home-about-us-section-column {
        margin: 0;
        padding: 0; }

    .about-us-img {
        text-align: right;

        img {
            max-width: 570px; } } }



/* ==================================== */
/* ========= IMAGE WALL SECTION ======= */

.images-wall-section {
    background-color: #f9f9f9;
    padding: 100px 0; }

.images-wall-section-text {
    text-align: center;
    max-width: 1020px;
    margin: 0 auto 80px;

    h2 {
        @include section-title;
        margin-bottom: 50px; }

    p {
        line-height: 30px; } }

.images-wallgrid {
    width: 1660px;
    height: 800px;
    margin: 0 auto;
    position: relative;

    .images-wall-item {
        position: absolute;
        overflow: hidden; }

    .square {
        width: 653px;
        height: 508px; }

    .vertical-medium {
        width: 342px;
        height: 396px; }

    .gorizontal {
        width: 653px;
        height: 283px; }

    .vertical-high {
        width: 322px;
        height: 508px; }

    .item-1 {
        left: 0;
        top: 0; }

    .item-2 {
        left: 663px;
        top: 0; }

    .item-3 {
        left: 1015px;
        top: 0; }

    .item-4 {
        left: 0;
        bottom: 0; }

    .item-5 {
        left: 663px;
        bottom: 0; }

    .item-6 {
        left: 1015px;
        bottom: 0; }

    .item-7 {
        left: 1346px;
        bottom: 0; } }


.image-link-block {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(255,255,255,0), rgba(0,0,0,.5));
        color: #fff;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        text-align: left;
        @include transition(.5s);

        &::after {
            content: "\f105";
            font-family: "FontAwesome";
            font-size: 21px;
            background-color: rgba(153,0,0,.5);
            width: 41px;
            position: absolute;
            right: 23px;
            bottom: 38px;
            color: #fff;
            border-radius: 50%;
            text-align: center;
            padding: 5px 0 6px; }

        &:hover {
            background-color: rgba(153,0,0,.7); }

        .image-link-block-text {
            position: absolute;
            bottom: 43px;
            left: 30px;
            width: 80%; } } }



.image-links-section {}

.image-links-row {
    text-align: center;
    font-size: 0;

    .image-links-item {
        width: 570px;
        height: 390px;
        display: inline-block;
        vertical-align: top;
        margin: 3px; } }



/* ==================================== */
/* ============ TOP PROGRAMS ========== */
.top-programs-section {
    padding: 90px 0;
    background-color: #f9f9f9;
    text-align: center;

    h2 {
        @include contentTitle;
        color: $title-color; }

    .button {
        @include button($color-vtheme, #fff);
        font-weight: bold; }

    .programs-list {
        text-align: center;
        font-size: 0;
        margin: 80px 0 50px; }

    .program-item {
        display: inline-block;
        vertical-align: top;
        width: 315px;
        height: 250px;
        @include shadow-block();
        margin: 0 23px 23px;

        p {
            font-size: 16px;
            line-height: 22px; } }

    .num-programs {
        font-size: 50px;
        text-transform: uppercase;
        text-align: center;
        display: block;
        color: $color-vtheme;
        font-family: 'Haettenschweiler', sans-serif;
        padding-bottom: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #dddddd; } }


.image-links-section {
    background-color: #f9f9f9;
    padding: 90px 0; }



/* ==================================== */
/* ============ TESTIMONIALS ========== */

.testimonials-section {
    background-color: #f9f9f9;
    padding-bottom: 90px; }

.testimonials-list {
    text-align: center;
    max-width: 1170px;
    margin: 0 auto; }

.testimonials-item {
    display: inline-block;
    vertical-align: top;
    width: 275px;
    height: 215px;
    @include shadow-block;
    padding: 40px 20px 35px;
    margin: 6px;
    position: relative;
    @include transition(.3s);
    &:hover {
        box-shadow: 0 0 46px rgba(0,0,0,.30);
        a {
            text-decoration: none; } }

    .testimonials-link {
        display: block;
        height: 100%;
        width: 100%;
        line-height: 26px;
        letter-spacing: 1px; }

    .testimonials-name {
        font-size: 18px;
        font-weight: 500;
        color: $title-color;
        text-transform: uppercase;
        line-height: 26px;

        .testimonials-num {
            color: $color-vtheme; } }

    .testimonials-info {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;

        &.social {
            color: $text-color;
            .fa-twitter {
                color: #1da1f2; } } } }




/* ==================================== */
/* =========== SPORTS CAROUSEL ======== */

.swiper-container {
    background-color: $color-vtheme;
    height: 150px;
    margin-top: 106px; }

.sports-list-section {
    opacity: 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 300px;
    background-color: rgba(29, 26, 26, .85);
    bottom: 85px;

    h2 {
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        position: absolute;
        left: 47px;
        top: 26px; }

    .close-sports-list {
        position: absolute;
        top: 46px;
        right: 41px;
        width: 20px;
        height: 20px;
        @include bgImage("../img/close-icon.png"); }

    .sports-list {
        height: 150px;
        width: 100%;
        background-color: $color-vtheme;
        position: relative;
        padding: 0 10px;
 }        // overflow: hidden

    .sport-item {
        width: 137px;
        height: 150px;
        position: relative;
        text-align: center;

        &:hover {
            .mw-sport-links {
                opacity: 1; }
            .sport-icon, h3 {
                opacity: .4; }
            &.white-bg {
                &::after {
                    opacity: .4; }
 }                // opacity: .4
            // &.white-bg
            //     &::after
 }            //         opacity: .4


        &.white-bg {
            h3 {
                color: $color-vtheme;
                z-index: 5; }

            .sport-icon {
                fill: $color-vtheme;
                z-index: 5;
                position: relative; }

            &::after {
                content: "";
                background-color: #fff;
                transform: rotate(7deg);
                height: 115%;
                width: 100%;
                display: block;
                position: absolute;
                top: -14px;
                z-index: 1;
                @include transition(.3s); } }

        .sport-info {
            @include transition(.3s); }

        .sport-icon {
            max-height: 55px;
            fill: #fff;
            margin-top: 25px;
            max-width: 70px;
            @include transition(.3s); }

        h3 {
            color: #fff;
            font-weight: bold;
            font-style: italic;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            margin-top: 0;
            margin-top: 5px;
            line-height: 17px;
            position: absolute;
            width: 100%;
            top: 95px;
            @include transition(.3s); } }


    .mw-sport-links {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 20;
        top: 0;
        left: 0;
        opacity: 0;
        @include transition(.3s);

        a {
            display: inline-block;
            background-color: #fff;
            font-weight: bold;
            font-size: 14px;
            color: $color-vtheme;
            text-transform: uppercase;
            width: 27px;
            text-align: center;
            padding: 9px 0 4px;
            margin: 63px 4px 0;
            line-height: 14px;
            &:hover {
                text-decoration: none; } }

 } }        // display: none
