@import "vars.sass";
@import "mixins.sass";
@import "menu.sass";
@import "header.sass";
@import "home.sass";
@import "page.sass";
@import "billsrights.sass";


//Load Libs SASS
@import "libs/animate/animate.sass";


/* ==================================== */
/* ========== GENERAL STYLES ========== */
* {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004); }

*::-webkit-input-placeholder {
	color: #666;
	opacity: 1; }

*:-moz-placeholder {
	color: #666;
	opacity: 1; }

*::-moz-placeholder {
	color: #666;
	opacity: 1; }

*:-ms-input-placeholder {
	color: #666;
	opacity: 1; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #666; }
body input:required:valid,
body textarea:required:valid {
	color: #666; }


.container {
	width: 1360px; }

img {
	max-width: 100%; }

a {
	@include transition(.3s); }

.iu-table {
	width: 100%;

	tr:nth-child(odd) {
		background-color: #f0f0f0; }

	td, th {
		padding: 10px 20px;
		font-size: 16px;
		width: 50%; }

	td {
		border-collapse: collapse;
		border: none;
		color: #7d7b7b; }

	th {
		background-color: #373737;
		color: #fff;
		text-transform: uppercase; }

	.total {
		td {
			color: $theme-color;
			font-weight: bold; } } }


/* ==================================== */
/* ============== FOOER =============== */

.footer {
	background-color: #323030;
	padding: 50px 0;
	text-align: right;
	font-size: 0;

	.container {
		position: relative; }

	h3, li, p, a {
		color: #fff;
		font-size: 14px; }

	h3 {
		font-size: 16px;
		text-transform: uppercase;
		margin-top: 0 {
	    margin-bottom: 10px; } }

	ul {
		@include reset-ul; }

	a {
		&:hover {
			color: $color-vtheme;
			text-decoration: none; } } }

.footer-column {
	display: inline-block;
	vertical-align: top;
	text-align: left;
	margin-right: 100px;
	font-size: 0;

	&:last-child {
		margin-right: 0; } }

.footer-logo-copyright {
	float: left;
	margin-right: 0; }

.copyright {
    position: absolute;
    left: 0;
    top: 90px;
    max-width: 25%; }

.footer-logo {
	width: 33px;
	height: 41px;
	display: block;
	background-image: url("../img/logo-white.png");
	background-repeat: no-repeat;
	background-position: center;
	background-size: 33px 41px; }

ul.social-links {
	text-align: right;
	font-size: 0;

	li {
		display: inline-block;
		vertical-align: top;
		margin-right: 20px;
		text-align: center;

		&:last-child {
			margin-right: 0; }
		a {
			width: 30px;
			height: 30px;
			background-color: #fff;
			border-radius: 50%;
			display: block;
			color: #323030;
			@include transition(.3s);
			font-size: 16px;
			line-height: 33px;
			&:hover {
				background-color: $color-vtheme;
				color: #fff; } } } }


.wmt-link {
	display: block;
	width: 142px;
	margin-top: 60px; }


.cbp-spmenu {
	overflow-y: scroll; }


/* ==================================== */
/* ============== MEDIA =============== */
@import "media";
