

/*==========  Desktop First Method  ==========*/

@media only screen and (max-width : 1900px) {
	// .image-pallet .image-pallet-vertical-image
	// 	position: static
	//
	// .image-pallet.image-block
	// 	text-align: right
	//
	// 	.image-pallet-vertical-image,
	// 	.image-pallet-square-image,
	// 	.image-pallet-horizontal-image
	// 		height: 200px
	// 		overflow: hidden
	// 		max-width: 30%
	// 		margin: 0 1%
	// 		width: auto
	//
	// 		img
	// 			height: 100%
	// 			width: auto
 }	// 			max-width: none


@media only screen and (max-width : 1700px) {
	.image-pallet .image-pallet-vertical-image {
		position: static; }

	.image-pallet.image-block {
		text-align: center;

		.image-pallet-main-image {
			margin-bottom: 30px;
			margin-left: 0;
			width: 100%; }

		.image-pallet-vertical-image,
		.image-pallet-square-image,
		.image-pallet-horizontal-image {
			height: 200px;
			overflow: hidden;
			max-width: 30%;
			margin: 0 1%;
			width: auto;

			img {
				height: 100%;
				width: auto;
				max-width: none; } } }

	.images-wallgrid {
		width: 100%;
		height: auto;
		text-align: center;

		.images-wall-item {
			position: static;
			display: inline-block;
			vertical-align: top;
			height: 250px;
			width: 30%;
			margin: .3%; } } }

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1441px) {
	.page-header,
	.home .page-header {
		height: 100vh; } }

@media only screen and (max-width : 1400px) {

	.container {
		width: 100%;
		box-sizing: border-box;
		padding-left: 30px;
		padding-right: 30px; }


	.footer-column {
		width: 20%;
		margin: 0;
		padding: 0 15px;
		box-sizing: border-box;
		float: none; }

	ul.social-links {
		text-align: left; }

	.redcorner::before {
		display: none; }

	.facilities-list {
		text-align: center; }

	.facilities-description-section {
		padding: 0 30px; } }


/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

	.footer-column {
		width: 100%;
		text-align: center;
		margin-bottom: 30px;

		.copyright {
			position: static;
			text-align: center;
			width: 100%;
			max-width: none;
			margin-top: 40px; }

		.footer-logo {
			margin: 0 auto; }
		.social-links {
			text-align: center; }

		.wmt-link {
			margin: 30px auto 0; } }

	.image-pallet .image-pallet-main-image {
		width: 100%;
		margin-left: 0;
 }		// padding-left: 40px

	.image-pallet-section .text-section {
		padding-left: 40px;
		padding-right: 40px; }

	.image-pallet .image-pallet-vertical-image,
	.image-pallet .image-pallet-square-image,
	.image-pallet .image-pallet-horizontal-image {
		position: static;
		width: 23%; }

	.image-pallet>div {
		margin-left: 20px;
		margin-bottom: 20px; }

	.text-section {
		padding-left: 40px;
		padding-right: 40px; }

	.content-left .big-content-image {
		padding-right: 0;
		width: 100%; }

	.content-right .big-content-image {
		padding-left: 0;
		width: 100%;
		transform: translateX(0); }

	.article-content.container.iu-list-block,
	.article-content.container,
	.nutrition-section .article-content.container.iu-list-block,
	.psychology .article-content.container.iu-list-block {
		width: 95%; }

	.slogan.condenced {
		font-size: 80px;
		line-height: 80px; }

	.page-header-text-block p {
		margin-top: 20px;
		padding: 0 40px; }

	.page-header.big-header {
		height: 100vh; }

	.coaches-section .coach-item {
		margin: 30px;

		&:last-child {
			margin-right: 30px; } }

	.article-content.container.coaches {
		padding-right: 0;
		padding-left: 0;
		width: 100%; }

	.coach-info.head-coach-info {
		padding: 0 20px; }

	.coaches-section {
		.head-coach .full-bio {
			position: relative;
			display: inline-block;
			left: 0;
			right: inherit;
			top: 0; }

		&.article-section p {
			margin-bottom: 10px; } } }



/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important; }

	.header.fixed .logo {
		left: 20px; }

	.header.fixed .header-menu {
		right: 20px; }

	.content-left,
	.content-right {
		width: 50%;
		float: none;
		width: 100%;
		position: static;
		transform: none;
		margin-bottom: 40px; }

	.big-content-image {
		height: 500px; }

	.text-section {
		margin: 0 auto;
		max-width: 680px; }

	.big-image-side,
	.small-image-side {
		height: auto; }

	.big-image-side .text-section,
	.small-image-side .text-section {
		position: static;
		transform: none; }

	.content-right {
		height: auto; }

	.slogan {
		font-size: 30px;
		line-height: 50px; }

	.image-pallet-section h2 {
		padding: 0 20px; }

	.content-section h2 {
		font-size: 24px;
		line-height: 40px;
		margin-bottom: 40px; }

	.image-pallet-section .text-section,
	.text-section {
	    padding-left: 20px;
	    padding-right: 20px; }

	.content-section {
		margin: 50px 0;

		&.coaches-section {
			margin-top: 0; } }

	.image-pallet .image-pallet-main-image {
		padding-left: 0; }

	.image-pallet.image-block {
		text-align: center; }

	.home-about-us-section h1 {
		font-size: 60px;
		line-height: 80px; }

	.home-about-us-section h2 {
		font-size: 30px; }

	.home-about-us-section .about-us-img {
		margin-top: 40px;

		img {
			width: 100%;
			max-width: 100%; } }

	.images-wall-section-text {
		max-width: 80%; }

	.images-wall-section-text h2 {
		font-size: 28px;
		margin-bottom: 30px;
		line-height: 40px; }

	.images-wallgrid .images-wall-item {
		position: static;
		width: 100%;
		margin-bottom: 15px; }

	.home .image-pallet-section .content-right,
	.home .image-pallet-section .content-right .text-section {
		position: static;
		transform: none; }

	.home-about-us-section ul {
		column-count: 1; }

	.coach-page-collage .coach-page-collage-text {
		padding: 100px; }

	.iu-page-carousel .base-size {
		width: 33.3%; }

	.iu-page-carousel .two-size {
		width: 66.6%; } }

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.image-links-row .image-links-item {
		width: 100%;
		margin: 10px 0; }

	.home-about-us-section h1 {
	    font-size: 40px;
	    line-height: 50px; }

	.home-about-us-section h2 {
	    font-size: 20px;
	    margin-bottom: 20px;
	    margin-top: 15px; }

	.top-programs-section .program-item {
		margin: 0 0 23px; }

	.home .select-team {
		font-size: 12px; }

	.home .i-map {
	    width: 300px;
	    height: 450px; }

	.home .home-logo {
		width: 150px;
		height: 200px;
		left: 96px; }

	.home-about-us-section {
		margin-top: 70px;
		margin-bottom: 60px; }

	.images-wall-section {
		padding: 60px 0; }

	.header .logo {
		top: 20px;
		left: 20px; }

	.header .header-menu {
		right: 20px;
		top: 30px; }

	.page-header.big-header {
		.slogan.condenced {
			font-size: 48px;
			line-height: 1; } }

	.page-header.big-header .button {
		font-size: 24px; }

	.logo-list .logo-list-item {
		width: 100%; }

	.coach-page-collage {
		height: 700px; }

	.coach-page-collage .coach-page-collage-image.bottom-left,
	.coach-page-collage .coach-page-collage-image.top-left {
		height: 50%; }

	.coach-page-collage .coach-page-collage-text {
		width: 70%; }

	.coach-page-collage .coach-page-collage-text {
		padding: 40px; }

	.coach-page-collage .coach-page-collage-text h2 {
		font-size: 24px;
		margin-bottom: 24px; }

	.coach-page-collage .coach-page-collage-text p {
		font-size: 14px;
		line-height: 1.5; }

	.coach-page-collage .coach-page-collage-image.right {
		background-position: center left -5px; }

	.iu-page-carousel .base-size,
	.iu-page-carousel .two-size {
		width: 50%; }

	.facilities-description-block-text,
	.facilities-description-block-image {
		width: 100%;
		padding-right: 0; }

	@media only screen and (max-height : 700px) {
		.page-header.big-header.full-height {
			height: auto;
			padding: 140px 0 60px;
			box-sizing: border-box;
			min-height: 100vh;
			overflow: hidden;

			.page-header-text-block {
				position: static;
				transform: none;
				position: relative;
				left: 0; } } } }

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	.top-programs-section .program-item {
		width: 100%; }

	.home .select-team {
	    bottom: 40px;
	    width: 80%;
	    text-align: center; }

	.coach-page-collage .coach-page-collage-text {
		width: 80%; }

	.iu-page-carousel .base-size,
	.iu-page-carousel .two-size {
		width: 100%; }

	.iu-page-carousel {
		height: 300px; }

	.iu-page-carousel .photo-carousel-item .photo-carousel-photo {
		background-position: center top; }

	.facilities-title-block .facilities-title {
		font-size: 28px;
		line-height: 45px; } }

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }
