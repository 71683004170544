/* ==================================== */
/* =========== PAGE HEADER ============ */

.page-header {
    height: 754px;
    width: 100%;
    position: relative;
    // background-image: url("../img/hero-pattern.png")
    // background-position: left top
    // background-repeat: repeat
    @include paralax-wrap;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -o-text-overflow: clip;
    text-overflow: clip;
    background-color: #990000;

    &.big-header {
        height: 880px;

        .button {
            @include button(#fff, $theme-color);
            margin: 55px auto 0;
            font-family: Haettenschweiler;
            font-size: 30px;
            display: block;
            max-width: 280px;
            text-align: center;
            padding: 15px 0;
            letter-spacing: 3px; } }

    .paralax-bg {
        background-position: top center; }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: -webkit-radial-gradient(circle, rgba(73,67,60,1) 0, rgb(63,57,50) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 100%);
        background: -moz-radial-gradient(circle, rgba(73,67,60,1) 0, rgb(63,57,50) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 100%);
        background: radial-gradient(circle, rgba(73,67,60,1) 0, rgb(63,57,50) 25%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 100%);
        background-position: 0 1em;
        -webkit-background-origin: padding-box;
        background-origin: padding-box;
        -webkit-background-clip: border-box;
        background-clip: border-box;
        -webkit-background-size: 6em 6em;
        background-size: 5px 5px;
        opacity: .2; } }

.page-header-text-block {
    @include positionCenter;
    width: 100%;
    z-index: 3;
    max-width: 1140px;

    p {
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 30px;
        margin-top: 80px; } }

.slogan {
    width: 100%;
    display: block;
    font-size: 70px;
    line-height: 90px;
    letter-spacing: 6px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    margin: 0;

    &.condenced {
        font-family: 'Haettenschweiler';
        font-size: 120px;
        letter-spacing: 15px;
        font-weight: normal; }

    &.regular {
        font-weight: normal; } }

.hero-cover-image {
    opacity: .1;
    background-repeat: no-repeat;
    background-size: cover; }


/* ==================================== */
/* =========== CONTENT STYLES ========= */

.content-section {
    @include clear;
    position: relative;
    margin: 100px 0;

    h2 {
        @include section-title;

        &.no-uppercase {
            text-transform: none; }

        span {
            color: $color-vtheme; } }

    h3 {
        @include contentTitle; }

    p {
        margin-top: 0; } }

.big-image-side {
    height: 830px;
    .text-section {
        @include vertical-center; } }

.small-image-side {
    height: 576px;
    max-width: 978px;
    margin: 0 auto 100px;
    position: relative;

    .text-section {
        @include vertical-center; }

    h2 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: .5px;
        font-weight: 500;
        margin-bottom: 40px; }

    .content-left.big-content-image {
        padding-right: 100px; }

    .big-content-image {
        width: calc(100% - 65px); } }

.content-left {
    position: relative;
    width: 50%;
    float: left;
    height: 100%;
    .big-content-image {
        padding-right: 150px; }
    .text-section {
        right: 0; } }

.content-right {
    position: relative;
    height: 100%;
    width: 50%;
    float: right;
    .text-section {}

    .big-content-image {
        padding-left: 150px;
        transform: translateX(150px); } }


.image-pallet-section {
    margin: 80px 0;
    @include clear;

    h2 {
        text-align: center;
        margin-bottom: 70px;
        padding: 0 50px; }

    .text-section {
        max-width: 680px;
        padding-left: 150px; } }

.image-pallet {
	position: relative;
	width: 100%;
	text-align: right;
	font-size: 0;
	overflow: hidden;

	// &>div
	// 	display: inline-block
	// 	vertical-align: top

	// .image-pallet-main-image
	// 	width: 100%
	// 	margin-left: 50px
	// 	display: block
	// 	margin-bottom: 50px
	// 	float: right
	// 	height: 465px
	// 	background-position: right bottom
	// 	background-repeat: no-repeat
	// 	background-size: 590px auto
	//
	// .image-pallet-vertical-image
	// 	width: 225px
	// 	position: absolute
	// 	right: 652px
	// 	top: 94px
	//
	// .image-pallet-square-image
	// 	width: 328px
	//
	// .image-pallet-horizontal-image
	// 	width: 368px
	// 	margin-left: 50px

	&>div {
		text-align: right; }

	.image-pallet-main-image {
		width: 590px;
		display: inline-block;
		vertical-align: bottom;
		margin-left: 30px; }

	.image-pallet-vertical-image {
		width: 225px;
		display: inline-block;
		vertical-align: bottom; }

	.image-pallet-square-image {
		width: 328px;
		display: inline-block;
		vertical-align: top;
		margin-top: 30px;
		margin-left: 30px; }

	.image-pallet-horizontal-image {
		width: 368px;
		display: inline-block;
		margin-top: 30px;
		margin-left: 30px;
		vertical-align: top; }

	.pallet-divider {
		clear: both; } }


.text-section {
    max-width: 600px;
    box-sizing: border-box; }

.big-content-image {
    box-sizing: border-box;
    height: 100%;
    width: calc(100% - 150px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

.redcorner {
    &::before {
        display: block;
        content: "";
        width: 150px;
        height: 150px;
        position: absolute;
        @include bgImage("../img/corner.png");
        left: -110px;
        top: -120px;
        z-index: -1; } }

/* ==================================== */
/* ========= PARALAX SECTION ========== */

.paralax-image-section {
    @include paralax-wrap;
    background-color: #171515;
    height: 680px;
    text-align: center;

    .paralax-bg {
        opacity: .4; }

    .paralax-image-section-info {
        @include positionCenter;

        * {
            color: #fff; }
        h2 {
            margin-bottom: 35px; }

        .button {
            @include button(#fff, $title-color); } } }



/* ==================================== */
/* ========== ACADEMY PAGE ============ */


.article-section {
    h2 {
        text-align: center;
        margin-bottom: 0; }

    h3 {
        text-align: center;
        font-weight: 500;
        color: #191919;
        margin-bottom: 50px; }

    p {
        margin-bottom: 30px; }

    .info-block {
        background-color: #f9f9f9;
        box-sizing: border-box;
        padding: 15px 20px;
        margin-bottom: 20px;

        h5,p {
            margin-bottom: 0;
            margin-top: 0;
            font-size: 16px;
            line-height: 22px; }

        h5 {

            font-weight: 500;
            text-transform: uppercase; }
        p {

            color: $theme-color; } }

    .article-title {
        margin-bottom: 65px;
        text-align: center;

        .subtitle {
            color: $theme-color;
            text-transform: uppercase;
            font-size: 18px;
            letter-spacing: 1px; } } }

.big-image-side {
    .button {
        @include button ($theme-color);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 11px 80px 10px;
        margin-top: 30px; } }



.article-big-image {
    max-width: 1355px;
    margin: 0 auto 50px; }


.article-content.container {
    width: 1008px;
    position: relative; }

.grey-bg {
    background-color: #f9f9f9; }

.article-content.container.iu-list-block {
    width: 1142px; }

.iu-list-block {
    box-sizing: border-box;
    padding: 35px 83px;
    border: 1px solid #dadada;
    margin-bottom: 25px;

    h4 {
        color: $theme-color;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 18px;
        margin-bottom: 40px;
        font-weight: normal;
        margin-top: 10px; }

    &.small-block {
        padding: 25px 35px 15px;
        h4 {
            text-align: left;
            margin-bottom: 25px; } }

    .iu-list {
        li {
            margin-bottom: 17px; } } }

.iu-list {
    @include reset-ul;

    li {
        padding-left: 20px;
        position: relative;
        line-height: 21px;
        margin-bottom: 20px;
        max-width: 570px;
        letter-spacing: -.5px;

        &::before {
            content: "";
            position: absolute;
            height: 11px;
            width: 9px;
            left: 0;
            top: 3px;
            background-image: url(../img/iu-logo.svg);
            background-position: left top;
            background-repeat: no-repeat;
            background-size: 9px 11px; } } }

.new {
    background-color: $theme-color;
    color: #fff;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: bold;
    padding: 8px 14px 5px;
    line-height: 12px;
    margin-bottom: 20px; }


.nutrition-section {
    .article-content.container.iu-list-block {
        width: 978px; }

    .article-content {
        margin-top: 80px;
        margin-bottom: 80px;

        p {
            margin-bottom: 0; } } }

.psychology {
    h3 {
        text-align: left;
        margin-bottom: 30px;
        margin-top: 20px; }

    .article-content.container.iu-list-block {
        width: 978px;
        margin-top: 40px;

        h4 {
            max-width: 560px;
            margin-left: auto;
            margin-right: auto; } } }



.coaches-section {
    background-color: #f9f9f9;
    overflow: hidden;
    margin-top: 0;
    padding-top: 100px;
    padding-bottom: 50px;

    .coaches-list {
        text-align: center;
        font-size: 0; }

    .coach-item {
        display: inline-block;
        vertical-align: top;
        width: 200px;
        margin-right: 59px;

        .coach-info {
            height: 130px; }

        * {
            text-align: left; }

        &:last-child {
            margin-right: 0; } }

    .coach-info {
        position: relative; }


    h3 {
        text-align: left;
        margin: 35px 0 0;
        line-height: 21px; }
    p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: .5px; }

    .coach-item {
        h3 {
            font-size: 18px;
            margin-bottom: 10px;
            margin-top: 20px;
            letter-spacing: .5px; }

        p {
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 5px; } }

    .coach-photo {
        img {
            width: 100%; } }

    .full-bio {
        color: $theme-color;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .5px;
        display: inline-block;
        position: absolute;
        bottom: 0;
        padding-right: 30px;

        &::after {
            content: "\f105";
            font-family: "FontAwesome";
            color: #fff;
            background-color: $theme-color;
            border-radius: 50%;
            position: absolute;
            right: 0;
            width: 20px;
            text-align: center;
            font-size: 12px;
            line-height: 16px;
            padding: 2px 0;
            display: top;
            top: 3px; } }


    .head-coach {
        .full-bio {
            position: absolute;
            right: 0;
            top: 10px; } } }


.logo-list {
    font-size: 0;
    text-align: center;

    h3 {
        color: #191919;
        margin-top: 50px;
        font-weight: 500; }

    .logo-list-item {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        box-sizing: border-box;
        padding: 10px 40px;
        margin-bottom: 45px;

        p {
            font-size: 14px;
            color: #6e6c6c;
            height: 40px;
            line-height: 18px;
            margin-top: -17px; }

        h5 {
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 21px;
            letter-spacing: .5px;
            color: #191919;
            margin-bottom: 35px;

            span {
                color: $theme-color;
                font-weight: bold;
                font-weight: 900; } } }

    &.extend {
        .logo-list-item {
            margin-bottom: 10px; } } }


.coach-page-collage {
    position: relative;
    height: 1150px;
    overflow: hidden;
    background-color: #f9f9f9;

    .coach-page-collage-text {
        position: absolute;
        background-color: #f9f9f9;
        padding: 150px;
        right: 0;
        top: 0;
        width: 60%;
        z-index: 3;

        p {
            max-width: 650px; }

        h2 {
            font-size: 50px;
            line-height: 1;
            text-transform: uppercase;
            font-weight: normal;
            letter-spacing: 2px;
            margin-bottom: 60px;
            color: #191919; } }

    .coach-page-collage-image {
        position: absolute;

        &.top-left {
            top: 0;
            left: 0;
            width: 50%;
            height: 660px;
            @include bgImage("../img/bb-image-5.jpg"); }

        &.bottom-left {
            bottom: 0;
            left: 0;
            width: 50%;
            height: 490px;
            @include bgImage("../img/bb-image-6.jpg"); }

        &.right {
            bottom: 0;
            right: 0;
            width: 50%;
            height: 660px;
            @include bgImage("../img/bb-image-7.jpg"); } } }


.iu-page-carousel {
    height: 540px;
    position: relative;

    .swiper-container {
        height: 100%;
        margin-top: 0; }

    .photo-carousel-list {
        height: 100%; }

    .photo-carousel-item {
        position: relative;
        height: 100%;

        .photo-carousel-photo {
            position: relative;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            @include transition(.4s);

            &:hover {
                opacity: .4; } }

        &.video-block {
            .photo-carousel-photo::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: .5; }

            a::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                width: 60px;
                height: 60px;
                @include bgImage('../img/play-video.svg');
                background-size: 60px;
                transform: translate(-50%, -50%);
                z-index: 5; } }


        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 4; } }

    .base-size {
        width: 20%; }

    .two-size {
        width: 40%; } }


/* ==================================== */
/* ========== FACILITIES PAGE ========= */
.facilities-list-section {
    background-color: #f9f9f9;
    overflow: hidden;
    margin-bottom: 70px; }

.facilities-list,
.facilities-description-section {
    font-size: 0;
    max-width: 1363px;
    text-align: left;
    margin: 40px auto;

    .facilities-item {
        width: 217px;
        height: 129px;
        display: inline-block;
        margin: 0 5px 9px;
        overflow: hidden;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-sizing: border-box;
        vertical-align: top;
        text-align: center;

        &.current {
            .facilities-item-link {
                border: 4px solid $theme-color;
                &:hover {
                    background-color: inherit; } } } }

    .facilities-item-info {
        padding: 20px;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        h3 {
            color: #fff;
            font-size: 13px;
            line-height: 1.2;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin: 10px 0 0 0; }

        img {
            max-height: 36px;
            max-width: 33px; } }

    .facilities-item-link {
        display: block;
        width: 100%;
        height: 100%;
        border: 4px solid rgba(255,255,255,0);
        @include transition(.5s);

        &:hover {
            background-color: rgba(153, 0, 0, .5); } } }


.facilities-description-item {
    margin-bottom: 20px;
    display: none;

    &.current {
        display: block; } }

.facilities-description-block-text,
.facilities-description-block-image {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;

    * {
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 30px;
        color: #312d2d;
        margin-top: 0; }

    h3 {
        color: $theme-color;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: bold; }

    li {
        line-height: 30px; }

    .iu-list {
        li:before {
            top: 7px; } }

    .facilities-description-info-title {
        font-weight: normal;
        font-size: 28px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 30px 0 50px; } }

.facilities-description-block-text {
    padding-right: 80px; }



.facilities-description-block-image {
    img {
        width: 100%; } }

.facilities-title-block {
    margin: 0 0 60px;
    text-align: center;

    .facilities-title {
        font-weight: normal;
        font-size: 45px;
        line-height: 45px;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #191919;
        margin-bottom: 25px; }

    .facilities-subtitle {
        display: block;
        text-transform: uppercase;
        color: #191919;
        letter-spacing: 1px;
        font-size: 24px; } }
