@import url('https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700');

.bill-right {
	* {
		color: $theme-color;
		font-family: 'Oswald', sans-serif; } }

.three-columns {
	// max-width: 1350px
	margin: 50px auto 80px;

	// -webkit-column-count: 3
 // // -moz-column-count: 3
 // // column-count: 3
	//
 // // -webkit-column-gap: 40px
 // // -moz-column-gap: 40px
 // // column-gap: 40px

 // // -webkit-column-rule-style: solid
 // // -moz-column-rule-style: solid
 // // column-rule-style: solid

	// -webkit-column-rule-width: 50px
 // // -moz-column-rule-width: 50px
 // // column-rule-width: 50px

	.column-section {
		padding-right: 40px;
		margin-bottom: 40px; }

	.content-order-number {
		float: left;
		font-size: 100px;
		font-weight: 700;
		display: block;
		line-height: 80px;
		margin-right: 22px;
		margin-bottom: 10px; }

	h2 {
		color: $theme-color;
		margin: 0;
		padding: 0;
		font-weight: bold;
		letter-spacing: normal;
		font-size: 21px;
		line-height: 1;
		margin-bottom: 10px; }

	p {
		font-size: 18px;
		line-height: 1.5;
		font-weight: 300; }

	.content-order-letter {
		display: inline-block;
		margin-right: 10px;
		font-weight: 700;
		text-transform: uppercase; } }


@media only screen and (max-width : 992px) {
	.content-section {
		&.three-columns {
			h2 {
				font-size: 18px;
				line-height: 1;
				margin-bottom: 10px; }

			p {
				font-size: 14px; }

			.content-order-number {
				font-size: 50px;
				line-height: 45px;
				margin-right: 10px;
				margin-bottom: 0; }

			.column-section {
				padding-right: 0; } } } }

@media only screen and (max-width : 768px) {
	.content-section {
		&.three-columns {
			h2 {
				font-size: 24px;
				line-height: 1.2;
				margin-bottom: 10px; }

			p {
				font-size: 16px; }

			.content-order-number {
				font-size: 50px;
				line-height: 45px;
				margin-right: 10px;
				margin-bottom: 0; } } } }
