@mixin transition($time, $animation: ease-out) {
    -webkit-transition: all $time $animation;
    -ms-transition: all $time $animation;
    -moz-transition: all $time $animation;
    transition: all $time $animation; }

@mixin bgImage($imageUrl) {
    background-image: url($imageUrl);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

@mixin contentTitle () {
    font-size: 24px;
    margin: 30px 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: normal; }

@mixin button ($color: $title-color, $hover-text-color: #fff) {
    display: inline-block;
    vertical-align: top;
    border: 2px solid $color;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 33px;
    color: $color;
    @include transition(.5s);

    &:hover {
        background-color: $color;
        color: $hover-text-color;
        text-decoration: none; } }

@mixin shadow-block ($border-color: $theme-color) {
    background-color: #fff;
    border-top: 5px solid $border-color;
    padding: 25px;
    box-shadow: 0 0 46px rgba(0,0,0,.06);
    @include transition(.5s); }


@mixin section-title($color: $title-color) {
    font-size: 45px;
    color: $color;
    margin-bottom: 65px;
    text-transform: uppercase;
    letter-spacing: 5px;
    line-height: 60px;
    margin-top: 0;
    font-weight: normal; }

@mixin vertical-center {
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%); }

@mixin positionCenter () {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

@mixin paralax-wrap {
    position: relative;
    overflow: hidden;

    .container, .container-fluid {
        position: relative;
        z-index: 2; }

    .paralax-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 150%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1; } }

@mixin clear {
    &::before {
        clear: both;
        content: "";
        display: block; }
    &::after {
        clear: both;
        content: "";
        display: block; } }

@mixin reset-ul {
    margin: 0;
    padding: 0;
    li {
        margin: 0;
        padding: 0;
        list-style: none; } }
