body {
	font-size: 16px;
	min-width: 320px;
	position: relative;
	line-height: 30px;
	font-family: $base-font;
	overflow-x: hidden;
	background-color: #fff;
	color: $text-color;
	position: relative; }

.hidden {
	display: none; }

.header {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 20;

	.logo {
		position: absolute;
		left: 48px;
		top: 47px;
		width: 53px;
		height: 59px;
		background-color: #fff;
		box-sizing: border-box;
		@include bgImage('../img/logo.png');
		background-size: 27px 35px;
		display: block; }

	.header-menu {
		position: absolute;
		right: 40px;
		top: 60px;
		color: #fff;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: bold;

		&::after {
			content: "";
			display: inline-block;
			width: 29px;
			height: 25px;
			@include bgImage('../img/burger-menu.png');
			margin-left: 21px;
			top: 4px;
			position: relative; } }

	&.fixed {
		@include transition(.3s);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background-color: $color-vtheme;

		.header-menu {
			top: 15px; }

		.logo {
			width: 40px;
			height: 49px;
			top: 5px; } } }

.cbp-spmenu-push-toleft {
	.header.fixed {
		left: -300px; } }
